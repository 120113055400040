import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Logo_icon } from "../imagepath";
import { MdOutlineSportsHandball } from "react-icons/md";

class SidebarNav extends Component {
  componentDidMount() {}

  render() {
    if (this.props.isAuthenticated) {
      const exclusionArray = [
        "login",
        "register",
        "forgot-password",
        "lock-screen",
        "error-404",
        "error-500",
      ];
      if (
        exclusionArray.indexOf(this.props.location.pathname.split("/")[1]) >= 0
      ) {
        return "";
      }
      const { location } = this.props;
      const pathname = location.pathname.split("/")[1];
      console.log(pathname);

      return (
        <>
          {/* Sidebar */}
          <div className="sidebar" id="sidebar">
            <div className="sidebar-logo">
              <Link to="/">
                <img src={Logo_icon} className="img-fluid" alt="" />
              </Link>
            </div>
            <div
              style={{ overflow: "auto" }}
              className="sidebar-inner slimscroll"
            >
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  <li
                    className={`${
                      pathname === "/" || pathname === "" ? "active" : ""
                    }`}
                  >
                    <Link to="/">
                      <i className="fas fa-columns" /> <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "profile" ? "active" : ""}`}>
                    <Link to="/profile">
                      <i className="fas fa-user" /> <span>Profile</span>
                    </Link>
                  </li>
                  {this.props.roles.includes("EDITOR") && (
                    <li className={`${pathname === "admins" ? "active" : ""}`}>
                      <Link to="/admins">
                        <i className="fas fa-layer-group" />
                        <span>Admins</span>
                      </Link>
                    </li>
                  )}
                  {this.props.roles.includes("EDITOR") && (
                    <li
                      className={`${pathname === "business" ? "active" : ""}`}
                    >
                      <Link to="/business">
                        <i className="fas fa-layer-group" />
                        <span>Hospital</span>
                      </Link>
                    </li>
                  )}
                  {this.props.roles.includes("EDITOR") && (
                    <li className={`${pathname === "doctors" ? "active" : ""}`}>
                      <Link to="/doctors">
                        <i className="fas fa-layer-group" />
                        <span>Doctors</span>
                      </Link>
                    </li>
                  )}
                  {this.props.roles.includes("EDITOR") && (
                    <li
                      className={`${pathname === "patients" ? "active" : ""}`}
                    >
                      <Link to="/patients">
                        <i className="fas fa-layer-group" />
                        <span>Patients</span>
                      </Link>
                    </li>
                  )}
                  {this.props.roles.includes("EDITOR") && (
                    <li
                      className={`${pathname === "template" ? "active" : ""}`}
                    >
                      <Link to="/template">
                        <i className="fas fa-layer-group" />
                        <span>Template List</span>
                      </Link>
                    </li>
                  )}

                  {this.props.roles.includes("EDITOR") && (
                    <li
                      className={`${pathname === "case-list" ? "active" : ""}`}
                    >
                      {/* <Link to="/case-list">
                        <i className="fas fa-layer-group" />
                        <span>CaseList</span>
                      </Link> */}
                    </li>
                  )}
                  {this.props.roles.includes("EDITOR") && (
                    <li
                      className={`${
                        pathname === "cases-without-doctor-list" ? "active" : ""
                      }`}
                    >
                      <Link to="/cases-without-doctor-list">
                        <i className="fas fa-layer-group" />
                        <span>Case List</span>
                      </Link>
                    </li>
                  )}
                  <li className={""}>
                    <a onClick={this.props.logOut}>
                      <i className="fas fa-lock" />
                      <span> Log Out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Sidebar */}
        </>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(SidebarNav);
