import callApi from "./util/apiCaller";
import {APP_TOKEN} from "./constants/enums/appEnums";

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_USER = 'SET_USER';
export const SET_PLANTS = 'SET_PLANTS';
export const SET_BUSINESS = 'SET_BUSINESS';

export const setIsLoading = isLoading => ({
    type: SET_IS_LOADING,
    isLoading,
});

export const setCompany = company => ({
    type: SET_COMPANY,
    company,
});

export const setPlants = plants => ({
    type: SET_PLANTS,
    plants,
});

export const setUser = (user, isAuthenticated) => ({
    type: SET_USER,
    user,
    isAuthenticated,
});
export const setBusiness = business => ({
    type: SET_BUSINESS,
    business,
})
// export function getPlants(filters) {
//     return async dispatch => {
//         try {
//             const res = await callApi(`plant/filter?${filters}`, 'get', null, true);
//             if (res && res.statusCode === 200) {
//                 dispatch(setPlants(res.body.records));
//             }

//         } catch (err) {
//             console.log(err);
//         }
//     }
// }

// export function getCompanyDetails(filters) {
//     return async dispatch => {
//         try {
//             const res = await callApi(`company/filter?${filters}`, 'get', null, true);
//             if (res && res.statusCode === 200) {
//                 dispatch(setCompany(res.body.records[0] ?? null));
//             }
//         } catch (err) {
//             console.log(err);
//         }
//     }
// }

// export function loginUser(creds) {
//     return async dispatch => {
//         try {
//             dispatch(setIsLoading(true));
//             const res = await callApi('auth/user/login', 'post', creds);

//             dispatch(setIsLoading(false));
//             if (res && res.status === 'Success') {
//                 const { token } = res.data;
//                 window.localStorage.setItem(APP_TOKEN, token);
//                 window.location = "/";
//             } else {
//                 dispatch(setIsLoading(false));
//             }

//         } catch (err) {
//             dispatch(setIsLoading(false));
//         }
//     }
// }
