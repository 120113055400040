//import Action
import {
    SET_COMPANY,
    SET_USER,
    SET_IS_LOADING,
    SET_PLANTS,
    SET_BUSINESS
} from './AppActions';

const initialState = {
    isAuthenticated: false,
    user: null,
    isLoading: false,
    message: "",
    _refurl: "",
    messageType:"",
    company: null,
    plants: [],
    business: {}
}

const AppReducer = (state = initialState, action) =>{
    switch (action.type) {
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            }
        case SET_USER:
            return {
                ...state,
                user: action.user,
                isAuthenticated: action.isAuthenticated,
            }
        case SET_COMPANY:
            return {
                ...state,
                company: action.company,
            }
        case SET_PLANTS:
            return {
                ...state,
                plants: action.plants,
            }
        case SET_BUSINESS:
            return {
                ...state,
                business: action.business,
            }
        default:
            return state;
    }
}


/* Selectors */
//login
export const getAuthenticatedState = state => state.app.isAuthenticated;
export const getPasswordProcessingState = state => state.app.isFetching;
export const getLoginMessage = state => state.app.message;
export const getLoginMessagetype = state => state.app.messagetype;
export const getRefUrl = state => state.app._refurl;
export const getCompany = state => state.app.company;
export const getUser = state => state.app.user;
export const getPlants = state => state.app.plants;
export const getBusiness = state => state.app.business;

//Export Reducer
export default AppReducer;
